.h1 {
}
.h2 {
  text-transform: uppercase;
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-weight: 900;
  width: 100%;
}

.h3 {
  font-family: Helvetica Neue;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
