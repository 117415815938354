@import "colors.css";

.container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0);
  transform: translateZ(0);
}

.actions {
  display: flex;
  align-items: center;
}

.btn {
  background-color: #28793e;
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  font-size: 14px;
}

.menuBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 20px;
  margin: 0;
}

html {
  overflow-y: scroll;
}

button .ArrowIcon {
  will-change: transform;
}

.logo {
  width: 35px;
  height: 35px;
  max-height: 35px;
  object-fit: contain;
}

.logoContainer {
  display: flex; /* Enable flexbox */
  align-items: center; /* Vertically align items */
  flex-direction: row; /* Ensure elements are in a row */
  width: 50%;
  cursor: pointer;
}

.scrbTitle {
  margin-left: 0.5rem; /* Add some spacing between the logo and the title */
}

@media (min-width: 1025px) {
  .container {
    box-sizing: border-box;
    height: 7.8125dvh;
    justify-content: space-around;
    border-bottom: 1px solid #b3b3b3;
  }
}
