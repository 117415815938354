.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  align-items: center;
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.icons {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-evenly;
}

.image {
  width: 60%;
  height: auto;
  object-fit: contain;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    height: auto;
    gap: 5dvw;
    padding-top: 13.4766dvh;
  }

  .content {
    gap: 1.667dvw;
    align-items: flex-start;
    padding-bottom: 1dvh;
    justify-content: space-between;
    max-width: 40dvw;
  }

  .icons {
    justify-content: flex-start;
    gap: 2dvh;
    margin: 0;
    margin-top: 10dvh;
  }

  .imageDesktopWrapper {
    display: flex;
    justify-content: center;
    width: 29.8dvw;
    margin-right: 5.5dvw;
    align-items: flex-end;
    height: 100%;
  }

  .imageDesktop {
    max-height: 100%;
    object-fit: contain;
    margin: 0;
  }
}
