.container {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.modalContent {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-sizing: border-box;
  background: white;
  padding: 2.5rem;
  width: 100%;
  padding-top: 5rem;
}
.modalContent li {
  list-style-type: none;
  font-weight: 500;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1%;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}
.modalContent li:hover {
  color: #28793e;
}

.blur {
  position: fixed;
  height: 100dvh;
  width: 100%;
  top: 0;
  z-index: -2;
  background-color: #cecece4f;
  backdrop-filter: blur(15px);
  cursor: pointer;
}

.socContainer {
  margin-top: 32px;
}

@media (min-width: 1025px) {
  .modalContent {
    position: absolute;
    width: 50%;
    right: 0;
    top: 7.8125dvh;
    height: calc(100dvh);
    padding-top: 4.8dvh;
    gap: 3.5dvh;
  }
  .modalContent li {
    font-size: 3.12dvh;
    line-height: 3.12dvh;
  }
  .socContainer {
    margin-top: 0;
  }
  .blur {
    height: calc(100dvh - 7.8125dvh);
    top: 7.8125dvh;
  }
}
