body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

code {
  font-family: "Helvetica Neue" source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

button {
  cursor: pointer;
  border: none;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/assets/fonts/Poppins-ExtraBold.ttf") format("truetype"); /* Adjust path and format as necessary */
  font-weight: 900;
  font-style: normal;
}

h1,
h2 {
  font-family: "Poppins";
  margin: 0;
}

p,
h3,
h4,
h5,
h6 {
  margin: 0;
}
