.cardContainer {
  border-radius: 10px;
  padding: 20px;
  width: 75dvw;
  max-width: 319px;
  background-color: #daeee380;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleWrapper {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  flex-wrap: nowrap;
  padding-bottom: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.title {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.subtitle {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
}

.descriptionWrapper {
  position: relative;
  width: 271px;
  height: 132.49px;
  left: 0;
  top: 0;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
}

.description {
  font-size: 0.9rem;
  color: #333;
  margin: 0;
  padding: 0;
}

.imageWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.image_small {
  width: 21.125%;
  height: auto;
}

.image_medium {
  width: 42.5%;
  height: auto;
}

.image_large {
  width: 100%;
  height: auto;
}

@media (min-width: 1025px) {
  .cardContainer {
    width: 22dvw;
    height: 100%;
  }
}
