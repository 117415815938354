.input {
  box-sizing: border-box;
  padding: 10px 16px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #929292;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-radius: 10px;
  border: 1px solid #b3b3b3;
  width: 100%;
  transition: border-color 1s;
}

.input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #929292;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.input:focus {
  border-color: #28793e;
  outline: none;
}

.error {
  border-color: #f44336;
}

.errorText {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #f44336;
}

@media (min-width: 1025px) {
  .input {
    height: 4.5dvh;
    font-size: 14px;
    line-height: 22px;
  }
  .input::placeholder {
    height: 4.5dvh;
    font-size: 14px;
    line-height: 22px;
  }
}
