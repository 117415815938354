.slider_container {
  position: relative;
  width: 100%;
  margin-top: 50px;
  margin: 0 auto;
}
.custom_swiper {
  justify-content: center;
}
.slider_buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  gap: 18px;
  padding-right: 3rem;
}

.slider_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ecf6f0;
  border: none;
  width: 44px;
  height: 44px;
  padding: 13px 0px 13.4px 0px;
  gap: 0px;
  border-radius: 100px;
  opacity: 0px;
  opacity: 1;
}

.slider_button:active {
  background-color: #ecf6f0;
}

.left_btn {
  transform: rotate(180deg);
}

.disabled {
  opacity: 0.5;
}
