.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}
.infoList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-left: 5%;
}

.infoListItem {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-decoration: dotted;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartImg {
  width: 90%;
}

@media (min-width: 1025px) {
  .chartImg {
    width: 30dvw;
  }
  .info {
    height: 100%;
    justify-content: space-between;
    gap: 25px;
  }
}
