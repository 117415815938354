.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: #28793e;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:disabled {
  background-color: #28793e;
  cursor: not-allowed;
}

.button:hover:not(:disabled) {
  background-color: #28793e;
}

.icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
}
