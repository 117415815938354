.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 18px;
}

.sliderButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 100px;
  padding: 0;
  opacity: 1;
  cursor: pointer;
}

.sliderButton:active {
  opacity: 1;
}

.sliderButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.leftBtn {
  transform: rotate(180deg);
}
