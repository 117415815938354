@media (min-width: 1025px) {
  .blockContainer {
    transform: rotate(-90deg) translateY(100%) scale(2.5);
  }
  .block {
  }

  .blockText {
    transform: scale(-1) translateY(-100%) translateX(-100%);
  }
}
