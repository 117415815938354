.container {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  top: 0;
  left: 0;
  height: 100dvh;
  z-index: 0;
}

.modalContent {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  background: white;
  padding: 2.5rem;
  width: 100%;
}

.crossBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  color: #000000;
}

.subTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
}

.connected {
  width: 100%;
  margin-bottom: -40px;
}

.list {
  padding-left: 10px;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.joinedImage {
  margin-top: 22px;
  width: 100%;
}

.blur {
  position: fixed;
  height: 100dvh;
  width: 100%;
  top: 0;
  z-index: -2;
  background-color: #cecece4f;
  backdrop-filter: blur(15px);
}
