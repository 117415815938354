.container {
  width: 100%;
  padding-bottom: 30px;
}
.title {
  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
  padding-left: 5%;
  padding-bottom: 23px;
}
.tickerWrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 35px;
  display: flex;
  align-items: center;
}

.tickerContent {
  display: flex;
  align-items: center;
  gap: 30px;
  animation: scroll 20s linear infinite;
}

.icon {
  height: 35px;
  align-self: center;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (min-width: 1025px) {
  .container {
    padding-bottom: 0;
    margin-bottom: 30px;
  }
  .title{
    text-align: center;
    color: black;
    font-weight: 300;
  }
  .tickerWrapper {
    height: 3dvw;
  }
  .tickerContentDesktop {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 5dvw;
    height: 100%;
  }
  .icon {
    height: 100%;
  }
}
