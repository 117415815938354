.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 25px;
  height: 100dvh;
  width: 100%;
  margin-bottom: 5%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.prgrph {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
}

.cardsContainer {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* for smoother scrolling on iOS */
  scroll-behavior: smooth; /* Smooth scrolling */
  gap: 20px; /* Adjust spacing between cards */
}

.slider {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.plansCardWrapper {
  scroll-snap-align: center; /* Ensures each card is centered */
}

.cardsContainer::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in Webkit browsers */
}

.cardsContainer {
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.cardBtns {
  margin-right: 10%;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding-top: 8.985dvh;
  }

  .cardBtns {
    max-height: 44dvh;
    margin: auto;
  }
}
