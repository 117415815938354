.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  align-items: center;
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.hand {
  height: auto;
  width: 75%;
  margin-left: 15%;
}
.btn {
  position: absolute;
  bottom: 2.5%;
  width: 85%;
  box-sizing: border-box;
  height: 44px;
  background-color: #28793e;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    gap: 5dvw;
    padding-top: 9.961dvh;
  }

  .content {
    flex: 1;
    max-width: 40dvw;
  }

  .hand {
    width: 40dvw;
    height: auto;
    object-fit: contain;
    margin: 0;
    align-self: flex-end;
    max-height: 80dvh;
  }

  .desktopBtn {
    height: 44px;
    background-color: #28793e;
    border-radius: 50px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 350px;
  }
}
