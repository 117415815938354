.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 100dvh;
  width: 100%;
  gap: 20px;
  margin-bottom: 5%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.prgrph {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
}

.buttons {
  display: flex;
  gap: 12px;
  width: 100%;
}

.btn {
  flex-wrap: nowrap;
  min-height: 34px;
  border-radius: 20px;
  border: none;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: #daeee380;
  color: #3f3f3f;
  cursor: pointer;
}

.activeBtn {
  background-color: #28793e;
  color: #ffffff;
}

.Pdfbtn {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid #28793e;
  color: #28793e;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 100%;
  background-color: #fff;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .desktopContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    width: 100%;
    padding-top: 8.985dvh;
  }

  .desktopHeader {
    display: flex;
    flex-direction: column;
    gap: 1.8dvh;
    width: 100%;
  }

  .desktopContent {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
  }

  .netWorkContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Pdfbtn {
    width: auto;
    height: auto;
    margin-right: 10%;
    font-size: 1dvw;
    padding: 9px 19.5px;
  }
}
