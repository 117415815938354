.form {
  box-sizing: border-box;
  width: 100%;
  margin: 1.5rem auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
}

.formControl {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textarea {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  min-height: 132px;
  color: #929292;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-radius: 10px;
  border: 1px solid #b3b3b3;
  width: 100%;
  transition: border-color 1s;
}

.textarea::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #929292;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.textarea:focus {
  border-color: #28793e;
  outline: none;
}

.error {
  border-color: #f44336;
}

.errorText {
  color: #f44336;
  font-size: 12px;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 1rem;
}

.checkboxLabel {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  display: flex;
  align-items: start;
  gap: 8px;
}

.checkboxLabel input[type="checkbox"] {
  appearance: none;
  min-width: 24px;
  min-height: 24px;
  border: 1px solid #b3b3b3;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: border-color 0.2s ease-in-out;
}

.checkboxLabel input[type="checkbox"]:checked {
}

.checkboxLabel input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background-color: #28793e;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.checkboxLabel input[type="checkbox"]:checked::before {
  transform: translate(-50%, -50%) scale(1);
}
