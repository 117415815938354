.form {
  box-sizing: border-box;
  width: 100%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.formControl {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textarea {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  min-height: 100px;

  color: #929292;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-radius: 10px;
  border: 1px solid #b3b3b3;
  width: 100%;
  transition: border-color 1s;
}

.textarea::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #929292;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.textarea:focus {
  border-color: #28793e;
  outline: none;
}

.error {
  border-color: #f44336;
}

.errorText {
  color: #f44336;
  font-size: 12px;
}

@media (min-width: 1025px) {
  
}