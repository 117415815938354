.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 26px;
  height: 100dvh;
  width: 100%;
  margin-bottom: 5%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.slider {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.cardsContainer {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  gap: 20px;
}

.plansCardWrapper {
  scroll-snap-align: center;
}

.cardsContainer::-webkit-scrollbar {
  display: none;
}

.cardsContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cardBtns {
  margin-right: 10%;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    padding-top: 8.985dvh;
  }

  .desktopCardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
  }

  .desktopCards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 20dvw;
    max-width: 30%;
    gap: 12px;
    max-height: 30dvh;
  }

  .desktopCardImg {
    /* width: 22%; */
    height: 77px;
  }

  .desktopCardTitle {
    font-size: 22px;
    line-height: 24px;
    text-align: left;
  }

  .desktopCard {
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    color: #3f3f3f;
  }
}
