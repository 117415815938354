.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  height: 100dvh;
  width: 100%;
  overflow-x: hidden;
  flex: 1;
  margin-bottom: 5%;
}

.title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.leftSide {
  z-index: -2;
  position: absolute;
  left: 9.5%;
  display: flex;
  align-items: center;
  height: 80dvh;
}

.blocksContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  width: 100%;
}

.blockWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.blockItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 20px;
  height: calc(100dvh);
  max-height: 140px;
}

.blockItem svg {
  height: 100%;
  object-fit: contain;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.textContainer p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #3f3f3f;
}

.boldText {
  font-weight: 700;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    gap: 5rem;
    padding-top: 5%;
  }

  .title {
    font-size: 58px;
    line-height: 55px;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  .leftSide {
    z-index: -2;
    transform: rotate(90deg) scale(2) scaleY(2);
    position: absolute;
    left: 50%;
    height: 46.5dvh;
  }

  .blocksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5rem;
  }

  .blockWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  .blockItem {
    margin: 0;
    transform: translateX(75%);
  }

  .blockItem svg {
    height: auto;
  }

  .textContainer {
    gap: 5rem;
  }

  .textContainer p {
    font-size: 24px;
    line-height: 32px;
  }
}
