.cardContainer {
  border-radius: 25px;
  padding: 20px;
  width: 300px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #8dbfa4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.image_small {
  width: 100px;
  height: auto;
}

.image_medium {
  width: 150px;
  height: auto;
}

.image_large {
  width: 250px;
  height: auto;
}

.titleWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.title {
  font-size: 22px;
  margin: 0;
}

.subtitle {
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  margin: 0;
  padding-bottom: 10px;
  text-align: center;
}

.descriptionWrapper {
  width: 250px;
  height: 75px;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.description {
  font-size: 1rem;
  font-weight: 400;
  color: #333;
  margin: 0;
  padding: 0;
}

@media (min-width: 1025px) {
  .cardContainer{
    width: 18.4dvw;
    height: auto;
    border: none;
  }
  .contentWrapper {
    align-items: flex-start;
  
  }

  .imageWrapper {
    justify-content: flex-start;
    margin: 0;
  }

  .descriptionWrapper {
    text-align: left;
    padding: 0;
    padding-top: 10px;
  }

  .image_large {
    width: 200px;
    height: auto;
  }
}
