.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  padding: 0 5%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.image {
  margin: auto;
  width: 65%;
  height: auto;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    height: auto;
    gap: 5dvw;
    padding-top: 13.4766dvh;
    padding-left: 9.725dvw;
    padding-right: 9.725dvw;
  }

  .content {
    max-width: 40dvw;
  }

  .image {
    width: 40dvw;
    max-width: 35%;
    height: auto;
    max-height: 55dvh;
    object-fit: contain;
    margin: 0;
  }
}
