.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  align-items: center;
  height: 100dvh;
  padding: 0 5%;
  width: 100%;
  overflow-x: hidden;
  flex: 1;
}

.title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.prgrph {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
}

.formContainer {
  display: flex;
  flex: 1;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align to the top left */
  width: 100%; /* Full width of container */
  gap: 10px; /* Add consistent spacing between items (form and partners) */
}

.partnersContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.iconsContainer {
  display: flex;
  gap: 30px;
  align-items: center;
  position: relative;
  height: 35px;
  width: 100%;
  margin-bottom: 30px;
  justify-content: flex-end;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.iconsContainer::-webkit-scrollbar {
  display: none;
}

.iconsContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.icon {
  align-self: center;
  height: 35px;
  object-fit: contain;
  flex-shrink: 0;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .desktopContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    overflow-y: hidden;
    height: 100dvh;
    padding: 5%;
    overflow-x: hidden;
  }

  .desktopHeader {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .desktopContent {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 70%;
    justify-content: space-between;
    margin: auto;
  }

  .title {
    font-size: 58px;
    line-height: 55px;
    width: 100%;
  }
}
