.container {
  height: 100dvh;
  overflow: hidden;
  position: relative;
  touch-action: pan-y;
}

.content {
  transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  touch-action: none;
}

.section {
  height: 100dvh;
  flex-shrink: 0;
}

.scrollbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 100%;
  background: #d3f1e27a; /* Track color */
}

.scrollbarThumb {
  position: absolute;
  width: 100%;
  background: #61a781; /* Thumb color */
  transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}
