.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartImg {
  width: 75%;
}

@media (min-width: 1025px) {
  .chartImg {
    width: 25dvw;
  }

  .info {
    gap: 5dvh;
    height: 100%;
    justify-content: space-between;
  }
}
