.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100dvh;
  gap: 56px;
  padding: 0 5%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.cardsContainer {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* for smoother scrolling on iOS */
  scroll-behavior: smooth; /* Smooth scrolling */
  gap: 16px; /* Adjust spacing between cards */
}
.slider {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.cardBtns {
  margin-right: 10%;
}

.plansCardWrapper {
  scroll-snap-align: center; /* Ensures each card is centered */
}

.cardsContainer::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in Webkit browsers */
}

.cardsContainer {
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.advisorsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notable {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 23px;
  padding-left: 5%;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f3f3f;
}

/* Desktop-specific styles */
@media (min-width: 1025px) {
  .container {
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    padding-top: 8.985dvh;
  }

  .title {
    font-size: 58px;
    line-height: 55px;
    width: 100%;
  }

  .advisorsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
  }

  .notable {
    text-align: center;
    font-size: 28px;
    font-weight: 300;
    line-height: 32px;
  }

  
}
